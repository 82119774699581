const $ = jQuery;

$( document ).ready(function() {
	$('.archive-previous-btn').on('click', function(){
        var prevPageUrl = $('.archive-paginate .prev').attr('href');
        window.location.href = prevPageUrl;
	});
    $('.archive-next-btn').on('click', function(){
        var nextPageUrl = $('.archive-paginate .next').attr('href');
        window.location.href = nextPageUrl;
	});
});