document.addEventListener('DOMContentLoaded', function () {
	document.querySelector('.mini-cart').addEventListener('click', function () {
		if (
			event.target.id === 'overlay' ||
			event.target.className === 'close'
		) {
			return;
		}

		if (window.innerWidth > 1024) {
			document.querySelector('#mini-cart-items').style.right = '0';

			if (document.querySelector('#overlay')) {
				document.querySelector('#overlay').style.display = 'block';
			} else {
				let overlay = document.createElement('div');
				overlay.id = 'overlay';
				overlay.style.display = 'block';
				let miniCartItems = document.querySelector('#mini-cart-items');
				miniCartItems.parentElement.appendChild(overlay);
			}

			document.body.style.overflow = 'hidden';
		}
	});

	document
		.querySelector('.close-container .close')
		.addEventListener('click', closeMiniCart);

	document.body.addEventListener('click', function (event) {
		if (event.target.id === 'overlay') {
			closeMiniCart();
		}
	});

	function closeMiniCart() {
		let width = '-80vw';
		if (window.innerWidth < 541) {
			width = '-100vw';
		}

		document.querySelector('#mini-cart-items').style.right = width;

		document.querySelector('#overlay').style.display = 'none';
		document.body.style.overflow = '';
	}
});
